body {
    background-color: #eaeaea;
}

.ui.inverted.top.fixed.menu {
    background-color: dodgerblue;
}

table {
    font-size: 14px !important;
}

/* The navbar */
a {
    font-size: 14px !important;
}

form {
    font-size: 14px !important;
}

button {
    font-size: 14px !important;
}

/* The filter header */
.ui.blue.huge.header {
    font-size: 25px !important;
    font-weight: bold;
}

.column {
    padding: 0px !important;
}

/* Mobile layouts */
@media only screen and (max-width: 800px) {
    /* The data tables */
    table {
        font-size: 9px !important;
    }

    /* The navbar */
    a {
        font-size: 12px !important;
    }

    form {
        font-size: 12px !important;
    }

    /* The filter header */
    .ui.blue.huge.header {
        font-size: 16px !important;
    }

    .column {
        padding: 0px !important;
    }

    button {
        font-size: 12px !important;
    }
}
